export const data = {
  frontend: [
    {
      title: "Entertainment Website",
      link: "https://next-entertainment-website.vercel.app/",
      features: ["NextJS", "ChakraUI", "Redux"],
      description:
        "Multipage and responsive web app providing the frontend of a video streaming service.",
      screenshot: "entertainment-site.webp",
      githubLink: "https://github.com/djblackett/next-entertainment-website",
    },
    {
      title: "Countries with REST API",
      link: "https://djblackett.github.io/rest-countries-api/",
      features: ["React", "React Context", "React Router", "Styled Components"],
      description:
        "A single page app that allows you to browse and search information about all the countries of the world.",
      screenshot: "countries-site.webp",
      githubLink: "https://github.com/djblackett/rest-countries-api",
    },
    {
      title: "Rock, Paper, Scissors",
      link: "https://djblackett.github.io/rock-paper-scissors-react/",
      features: ["React", "React Context", "Styled Components"],
      description:
        "The classic game Rock, Paper, Scissors reimagined as a man vs machine challenge. ",
      screenshot: "rock-paper-scissors.webp",
      githubLink: "https://github.com/djblackett/rock-paper-scissors-react",
    },
    {
      title: "Todo List",
      link: "https://djblackett.github.io/todo-react/",
      features: ["React", "Redux", "SCSS", "ReactDnD", "Local Storage"],
      description:
        "A Todo list app with dark and light mode, and drag 'N' drop functionality. List is saved to local storage.",
      screenshot: "todo-app.webp",
      githubLink: "https://github.com/djblackett/todo-react",
    },
    {
      title: "Sunnyside Agency Landing Page",
      link: "https://djblackett.github.io/sunnyside-agency-landing-page/",
      features: ["HTML", "CSS", "Accessibility", "No Frameworks"],
      description:
        "A company landing page built from scratch with plain HTML and vanilla CSS. The design specs and assets are from Frontend Mentor. ",
      screenshot: "landing-page.webp",
      githubLink: "https://github.com/djblackett/sunnyside-agency-landing-page",
    },
    {
      title: "Triple API Todo List",
      link: "https://djblackett.github.io/inspirational-portfolio/",
      features: [],
      description:
        "Capstone project for Codecademy's Frontend Developer career path. Integrates the Unsplash, OpenWeather, and They Said So Quotes APIs with a basic todo list. ",
      screenshot: "triple-api-app-screenshot.webp",
      githubLink: "https://github.com/djblackett/inspirational-portfolio",
    },

    // { title: "", link: "", features: [], description: "", screenshot: "" },
  ],
};

export const hyperskillLinks = [
  {
    title: "Java Switch Expressions",
    href: "https://hyperskill.org/learn/step/16036",
  },
  {
    title: "Reducing Boilerplate Code with Lombok",
    href: "https://hyperskill.org/learn/step/13983",
  },
  {
    title: "Introduction to Java Modules",
    href: "https://hyperskill.org/learn/step/15647",
  },
  {
    title: "Java Text Blocks",
    href: "https://hyperskill.org/learn/step/17316",
  },
  {
    title: "Introduction to Gson",
    href: "https://hyperskill.org/learn/step/18251",
  },
  {
    title: "Customizing JSON with Gson",
    href: "https://hyperskill.org/learn/step/18259",
  },
];
