import "./App.css";
import styled from "styled-components";
import WorksSection from "./WorksSection";
import Articles from "./Articles";

const lightBlueGradient = `background: #e0eafc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #cfdef3,
    #e0eafc
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #cfdef3,
    #e0eafc
  ); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
  box-shadow: 2px 2px 5px 2px grey;`

const Nav = styled.nav`
  color: white;
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-bottom: 2px solid black;
  box-shadow: 2px 2px 5px 2px grey;
  
  /* margin-bottom: 2rem; */
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  a {
    color: whitesmoke;
  }

  @media (min-width: 900px) {
    justify-content: flex-end;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 1.5rem;
  padding: 0;
  /* margin-right: 1rem; */
  transition: all 0.3s linear;

  //todo scale on focus not working. come back to later

  a {
    &:focus li {
      transform: scale(1.2);
    }
  }

  li {
    transition: all 0.3s ease-in;
    &:hover {
      transform: scale(1.2);
    }
  }

  @media (min-width: 700px) {
    width: 50%;
    font-size: 2rem;
  }

  @media (min-width: 900px) {
    width: 45%;
    font-size: 2rem;
  }

  @media (min-width: 1200px) {
    width: 30%;
  }
`;

const Welcome = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
   background: rgba(255, 255, 255, 0.5);
   box-shadow: 2px 2px 5px 2px grey;

   @media (min-width: 900px) {
     min-height: 90vh;

   }
  
`;

const ProfilePic = styled.img`
  /* border-radius: 5%; */
  margin-top: 4rem;
  /* height: 250px; */
  width: 200px;
  aspect-ratio: 3 / 4;
  box-shadow: 2px 2px 5px 2px;
  transition: all 0.3s;

  &:hover {
    transform: rotate(10deg);
  }
`;

const Name = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  filter: drop-shadow(2.5px 2.5px 4px #4444dd);
  margin-bottom: 0;
`;

const Title = styled.p`
  margin-top: 0;
  font-size: 2rem;
  font-weight: 500;
`;

const Description = styled.p`
  font-size: 1.3rem;
  max-width: 1000px;
  text-align: center;
  line-height: 1.8;
`;

const Divider = styled.div`
  height: 2vh;
  width: 100%;
  box-shadow: 2px 2px 5px 2px;
  background: #283048; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #859398,
    #283048
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #859398,
    #283048
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

const AppContainer = styled.main`
    text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  background-image: url("blue-background-medium.webp");
  background-attachment: fixed;
`

function App() {

  return (
    <AppContainer>
      <Nav>
        <NavList>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#articles">Articles</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </NavList>
      </Nav>

      <Welcome>
        <ProfilePic src="profile-pic.webp" alt="profile" />
        <Name>Dave Andrea</Name>
        <Title>Full Stack Software Developer</Title>
        <Description>
          Welcome! I'm Dave, a software developer from Canada. Here you'll find
          a collection of frontend and backend apps I developed, along with some
          educational articles on the subject of computer programmming.
        </Description>
      </Welcome>
      {/* <Divider /> */}
      <WorksSection />
      <Articles />
      <Divider />
    </AppContainer>
  );
}

export default App;
