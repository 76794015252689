import styled from "styled-components";
import Project from "./Project";
import { data } from "./data";


const ProjectHeading = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  /* margin-bottom: 0; */
`;



const ProjectsGrid = styled.div`
  display: grid;
  grid-template: repeat(2, min-max(330px, 500px)) / 1fr;
  gap: 3rem;
  max-width: 1000px;
  justify-content: center;
  justify-items: center;
  margin-bottom: 3rem;

  @media (min-width: 650px) {
    grid-template: repeat(2, auto) / repeat(2, 300px);
  }

  @media (min-width: 1000px) {
    grid-template: repeat(2, auto) / repeat(3, 300px);
    row-gap: 6rem;
    margin-bottom: 6rem;
  }
`;

const WorksContainer = styled.div`
  /* background-image: url("white-background-medium.webp"); */
  background-attachment: fixed;
  background-size: cover;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
/* box-shadow: 2px 2px 2px 2px grey; */
`

function WorksSection() {
  return (
    <WorksContainer>
      <ProjectHeading id="projects">Frontend Projects</ProjectHeading>

      {/* <FrontendProjects>Frontend Projects</FrontendProjects> */}
      <ProjectsGrid>
        {data.frontend.map((project) => {
          return <Project project={project}></Project>;
        })}
      </ProjectsGrid>

      {/* <ProjectHeading>Backend Projects</ProjectHeading>
      <ProjectsGrid>


      </ProjectsGrid> */}
      
    </WorksContainer>
  );
}

export default WorksSection;
