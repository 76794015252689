import styled from "styled-components";
import { hyperskillLinks } from "./data";

const ArticlesHeading = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  /* margin-bottom: 0%; */
`;

const ArticlesDescription = styled.p`
  max-width: 1000px;
  font-size: 1.3rem;
  line-height: 1.8;
  padding: 0 1rem;
  margin-top: 0;

  @media (min-width: 600px) {
    font-size: 1.3rem;
    line-height: 1.8;
    margin-top: initial;
  }
`;

const ArticleList = styled.ul`
  list-style: none;
  text-align: left;
  max-width: 1000px;
  padding: 0 1rem;

  li a {
    color: black;
    font-size: 1.3rem;
    text-align: left;
    line-height: 2;
    /* width: 100%; */

    @media (min-width: 600px) {
      font-size: 1.3rem;
      line-height: 1.8;
    }
  }

  li {
    background-color: rgba(255, 255, 255, 0.5);
    margin: 1rem;
    border-radius: 16px;
    padding: 0 1rem;
    box-shadow: 2px 2px 5px 2px grey;
    transition: all .3s ease-in;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const ArticlesGrid = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;
  width: 100%;
  padding: 0;
  justify-items: center;
  max-width: 1000px;
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  /* box-shadow: 2px 2px 5px 2px grey; */

  @media (min-width: 600px) {
    grid-template: 1fr / 1fr 1fr;
    justify-items: start;
  }
`;

const ArticleCard = styled.div`
  
  height: 100%;
  min-height: 500px;

  border: 1px solid grey;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 2px rgba(35, 35, 35, 0.744);
  background: rgba(255, 255, 255, 0.5);

   @media (min-width: 600px) {
   width: 100%;
  }
`;

const Header = styled.h3`
  font-size: 2rem;
  /* text-align: left; */
`;

const ArticlesSection = styled.div`
  /* background-image: url("blue-background-medium.webp"); */
  background-attachment: fixed;
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* opacity: .02; */
`


function Articles() {
  return (
    <ArticlesSection>
      <ArticlesHeading id="articles">Articles</ArticlesHeading>
      <ArticlesDescription>
        My written articles are grouped into two main categories: education, and
        edutainment. Having spent time in both academia and the public school
        classroom, I appreciate the roles that both genres play when teaching
        others. Sometimes we want clear, precise, and concise. Other times,
        however, we can get further by injecting some humor and entertaining the
        audience a little.
      </ArticlesDescription>

      <ArticlesGrid>
        <ArticleCard>
          <Header>
            Java Tutorials
          </Header>
          <ArticleList>
            {hyperskillLinks.map((article) => {
              return (
                <li key={article.title + "11"}>
                  <a href={article.href} target="_blank" rel="noreferrer">
                    {article.title}
                  </a>
                </li>
              );
            })}
          </ArticleList>
        </ArticleCard>
        <ArticleCard>
        <Header>"Edutainment" Articles</Header>
          <ArticleList>
            <li>
              <a
                href="https://medium.com/@daveandrea/why-javascript-is-so-hard-for-beginners-b86eb818c4d7"
                target="_blank"
                rel="noreferrer"
              >
                Why Javascript Is So Hard for Beginners
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@daveandrea/why-is-java-so-dumb-1990b9ac77c4"
                target="_blank"
                rel="noreferrer"
              >
                Why is Java So Dumb? (Part 1)
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@daveandrea/why-is-java-so-dumb-part-2-25755eb8459a"
                target="_blank"
                rel="noreferrer"
              >
                Why is Java So Dumb? (Part 2)
              </a>
            </li>
          </ArticleList>
        </ArticleCard>
      </ArticlesGrid>
    </ArticlesSection>
  );
}

export default Articles;
