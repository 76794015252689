import styled from "styled-components";

const ProjectContainer = styled.div.attrs({
  tabIndex: 0,
})`
  max-width: 300px;
  width: 90%;
  /* height: 100%; */
  /* min-height: 330px; */
  aspect-ratio: 3 / 4;
  /* background-size: contain; */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 2px 2px 3px 2px grey;
  /* text-align: left; */
  /* filter: drop-shadow(2px 2px 2px black); */
  /* perspective: 500; */
  transition: all 0.3s ease-in;

  &:hover {
    transform: scale(1.1);
    box-shadow: 2px 2px 5px 4px grey;
    /* transform: perspective(3); */
  }
`;

const ProjectTitle = styled.a`
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
`;

const ProjectDescription = styled.p`
  line-height: 1.5;
  padding: 0 5%;

  @media (min-width: 350px) {
    padding: 0 7.5%;
  }
`;

function Project({ project }) {
  return (
    <ProjectContainer key={project.title + "22"}
    // style={{ backgroundImage: `url(${project.screenshot})` }}
    >
      <ProjectTitle href={project.link}>{project.title}</ProjectTitle>
      <ProjectDescription>{project.description}</ProjectDescription>
      <img
        src={project.screenshot}
        alt={project.title + " screenshot"}
        // height="150px"
        width="250px"
        style={{ aspectRatio: "2 / 1" }}
      />
    </ProjectContainer>
  );
}

export default Project;
